import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import Image from '../../components/Image'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/farmdog",
      "name": "Farmdog"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <ProjectsContext.Consumer clientName="Farmdog">
        {({ allProjects, currentProject }) => (
          <Fragment>
            <SEO
              path="/projects/farmdog"
              title="Featured React project for Phytech by 500Tech"
              description="We've re-written Farmdog's app originally made with AngularJS & Ionic, using in React Native"
              ld={breadcrumbs}
            />

            <ProjectName>{currentProject.projectName}</ProjectName>
            <Facts {...currentProject} />

            <RowGroup>
              <Row>
                <Three>
                  <p>
                    Farmdog develops a system for farmers to help scout their
                    fields and log their visits.
                  </p>

                  <p>
                    Farmdog had an existing app written with ionic, that was
                    sluggish and poorly written. We re-developed the app from
                    scratch using React Native and their existing APIs. We
                    maintained feature parity and increased the level of polish
                    and user experience.
                  </p>

                  <p>
                    During the development, Farmdog recruited in-house
                    developers, who worked together with our technical lead and
                    other developers. The project ended ahead of schedule, and
                    we did a full transition of the app and codebase to their
                    in-house team.
                  </p>
                </Three>
              </Row>
            </RowGroup>

            <Team team={currentProject.team} />

            <Testimonial />

            <RowGroup>
              <Row>
                <One>
                  <p>
                    The farmer can add custom fields using a simple Polygon
                    selection.
                  </p>

                  <p>
                    There was no built-in polygon creation to React Native Maps,
                    so we created one of our own. After the user touches a
                    location on the map we render a point, then they touch
                    another location, and we render a polyline, and each
                    location they touch further - we render a polygon.
                  </p>
                </One>
                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-creation.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>

                <One>
                  <Image path="projects/farmdog/farmdog-3.png" />
                  <Caption>
                    The app saves large datasets in persistent storage, and
                    loads them if the user is offline
                  </Caption>
                </One>

                <One>
                  <p>
                    One of the app’s requirements is to allow users to work in
                    offline mode as they are scouting the field.
                  </p>

                  <p>
                    We had to implement a custom tile caching logic, as it is
                    not supported by the React Native Maps engine. When the user
                    selects an area to visit, we get a grid of coordinates at
                    different zoom levels, get the relevant tiles from
                    Google/Mapbox, and cache them on the disk. When the user is
                    offline - we get the tiles from the cache instead of online
                    sources.
                  </p>
                </One>
              </Row>

              <Row>
                <Four>
                  Farmers can log visits and input their observations. Each
                  visit contains observation types, with different form controls
                  - according to the form metadata. He can then save the form
                  and load saved forms.
                </Four>
              </Row>

              <Row>
                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-dynamic-form.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>

                <One>
                  <Image path="projects/farmdog/farmdog-2.png" />
                  <Caption>
                    After the farmer logs a visit, he is prompted to input a
                    schedule for the next visit. He can then view and edit the
                    schedule.
                  </Caption>
                </One>

                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-reorder.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>

                <One>
                  <p>
                    The user can select from existing observation types, or add
                    new observation types that will be visible to all users in
                    the same group.
                  </p>

                  <p>
                    He can then reorder them, or remove them, which allows for
                    full flexibility when logging the visit.
                  </p>
                </One>
              </Row>

              <Row>
                <Two>
                  <p>
                    Each user can belong to a number of different groups, with
                    each group having different data and even a different
                    provider for map tiles (Google Maps or Mapbox)
                  </p>

                  <p>
                    Quick filtering is available, as each group can have
                    thousands of different fields
                  </p>
                </Two>

                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-groups.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>

                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-search.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>
              </Row>

              <Row>
                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-batch.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                </One>

                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-report.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                  <Caption>
                    Each field's observation can be edited from within the
                    sending report screen
                  </Caption>
                </One>

                <One>
                  <video
                    src="/assets/images/projects/farmdog/farmdog-photo.mp4"
                    autoPlay
                    muted
                    loop
                    style={{ width: '100%', maxHeight: '500px' }}
                  />
                  <Caption>
                    Farmers can also add photos of plantings they're checking to
                    their reports
                  </Caption>
                </One>

                <One>
                  <p>
                    Users can also select numerous fields, and update them at
                    once
                  </p>

                  <p>
                    The app also enables the user to select specific fields,
                    create, preview, and send reports to their customers
                  </p>
                </One>
              </Row>
            </RowGroup>

            <OtherProjects currentProject={currentProject} />
            <ContactForm />
          </Fragment>
        )}
      </ProjectsContext.Consumer>
    </Layout>
  )
}

const MediaContainer = styled.div`
  position: relative;
`
